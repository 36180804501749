import React from "react";
import { Link } from "components";
import ServiceLink from "./ServiceLink";
import YouTubeVideo from "./YouTubeVideo";
import VimeoVideo from "./VimeoVideo";
import MoreDetailsLink from "../MoreDetailsLink";

function getServiceLink(url) {
  // TODO: refactor it
  if (url.indexOf("android:") !== -1) {
    url = url.replace("android:", "");
    return <ServiceLink uri={url} service="androidApk" title="Android APK" />;
  }

  if (url.indexOf("youtube.com") !== -1) {
    // Youtube
    return <ServiceLink uri={url} service="youtube" title="YouTube" />;
  } else if (
    // Steam
    url.indexOf("steam.com") !== -1 ||
    url.indexOf("steampowered.com") !== -1
  ) {
    return <ServiceLink uri={url} service="steam" title="Steam" />;
  } else if (
    // Itch.io
    url.indexOf("itch.io") !== -1
  ) {
    return <ServiceLink uri={url} service="itch" title="Itch.io" />;
  } else if (
    // Github
    url.indexOf("github.com") !== -1
  ) {
    return <ServiceLink uri={url} service="github" title="GitHub" />;
  } else if (
    // APK on GoogleDrive
    url.indexOf("drive.google.com") !== -1
  ) {
    return <ServiceLink uri={url} service="googleDrive" title="Google Drive" />;
  } else if (
    // Stove
    url.indexOf("onstove.com") !== -1
  ) {
    return <ServiceLink uri={url} service="stove" title="STOVE" />;
  }
}

function getWidget(url) {
  // TODO: set general logic, not only YouTube

  if (url.indexOf("youtube.com") !== -1) {
    return <YouTubeVideo url={url} />;
  }

  if (url.indexOf("vimeo.com") !== -1) {
    return <VimeoVideo url={url} />;
  }
}

function hyperlink(node) {
  const uri = node.data.uri;

  if (uri.includes("icon:")) {
    const url = uri.replace("icon:", "");
    return getServiceLink(url);
  } else if (uri.indexOf("widget:") !== -1) {
    const url = uri.replace("widget:", "");
    return getWidget(url);
  }

  const text = node.content[0].value;

  if (text === "[More details]") {
    return <MoreDetailsLink path={uri} lang="en" />;
  }
  if (text === "[Детальніше]") {
    return <MoreDetailsLink path={uri} lang="ua" />;
  }
  if (text === "[Подробнее]") {
    return <MoreDetailsLink path={uri} lang="ru" />;
  }

  return (
    <Link to={uri} style={{ textDecoration: "underline", whiteSpace: "wrap" }}>
      {text}
    </Link>
  );
}

export default hyperlink;
